import React from "react";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <>
      {/* Footer area start */}
      <footer className="footer__area-3">
        <div className="container">
          
          <div className="footer__widgets-3">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <div className="footer__logo-3">
                  <img
                    src="assets/imgs/logo/logo.png"
                    alt="Footer Logo"
                    width={200} height={50}
                  />
                  
                  <div className="footer__social-3">
                    <ul>
                    <li>
                        <Link to="https://www.linkedin.com/company/securitr/">
                          <i className="fa-brands fa-linkedin" />
                        </Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/securitr_">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                     
                      <li>
                        <Link to="https://www.instagram.com/securitr.as/">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="footer__link-3">
                
                  <ul>
                    <li>
                      <Link to="/hakkimizda">Hakkımızda</Link>
                    </li>
                    <li>
                      <Link to="/referanslarimiz">Referanslarımız</Link>
                    </li>
                    <li>
                      <Link to="/hizmetlerimiz">Servislerimiz</Link>
                    </li>
                    <li>
                      <Link to="/iletisim">İletişim</Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="footer__address-3">
                  
                  <ul>
                    <li>
                      <span>
                        <i className="fa-solid fa-location-dot" />
                      </span>
                      <p>
                      Kanlıca mahallesi Hacı Muhittin sokak No 10 Beykoz/ Istanbul
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                      <div>
                        <Link to="tel:0544 915 28 28">0544 915 28 28</Link>
                      
                      </div>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                      <div>
                        <Link to="mailto:info@securitr.com.tr">
                          info@securitr.com.tr
                        </Link>
                        
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div className="footer__btm-3-wrap">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="footer__btm-3">
                  <div className="footer__copyright-3">
                    <p>
                      Copyright @2024 SecuriTR.
                    </p>
                  </div>
                  {/* <div className="footer__menu-3">
                    <ul>
                      <li>
                        <Link to="/about">Privacy Policy.</Link>
                      </li>
                      <li>
                        <Link to="/about">Terms Of Condition</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer area end */}
    </>
  );
};

export default FooterThree;
